import React, { useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./NewsRoom.module.css";
import Marquee from "react-fast-marquee";
import img12 from "../../assets/images/logo/image_12.png";
import img13 from "../../assets/images/logo/image_13.png";
import img14 from "../../assets/images/logo/image_14.png";
import img16 from "../../assets/images/logo/image_16.png";
import img2 from "../../assets/images/logo/image_2.png";
import CrescentLogo from "../../assets/images/logo/CrescentFoundry.png";
import JupiterLogo from "../../assets/images/logo/image.png";
import RBAgarwalla from "../../assets/images/logo/RB Agarwalla.png";
import img5 from "../../assets/images/logo/image_5.png";
import NIPHA from "../../assets/images/logo/NIPHA.png";
import ShantiFouMach from "../../assets/images/logo/Shanti FouMach Private Limited.png";
import media5 from "../../assets/images/home/media/media5.png";
import media6 from "../../assets/images/home/media/media6.png";
import media8 from "../../assets/images/home/media/media8.png";
import media9 from "../../assets/images/home/media/media9.png";
import media10 from "../../assets/images/home/media/media10.png";
import FTmedia from "../../assets/images/home/media/FTnew.png";
import TheEconimicTimes from "../../assets/images/logo/TheEconomicTimes.png";
import BusinessStandard from "../../assets/images/logo/BusinessStandard.png";
import media1 from "../../assets/images/home/media/media3.png";
import media2 from "../../assets/images/home/media/media1.png";
import media3 from "../../assets/images/home/media/media2.png";
import media4 from "../../assets/images/home/media/media4.png";
import Mint from "../../assets/images/logo/mintLogo.png";
import Container from "../../Components/General/Container";
import MediaInvestor from "./MediaInvestor";

const Logos = [
  img14,
  img2,
  img12,
  CrescentLogo,
  img13,
  RBAgarwalla,
  img5,
  JupiterLogo,
  NIPHA,
  ShantiFouMach,
  img16,
];

const Logo = ({ src }) => <img src={src} alt="logo" className={styles.logo} />;

export const LogoContainer = () => (
  <Marquee
    gradientColor="transparent"
    speed={40}
    gradient={true}
    gradientWidth={100}
    pauseOnHover={true}
  >
    {Logos.map((logo, index) => (
      <Logo key={index} src={logo} />
    ))}
  </Marquee>
);

const Card = ({ children, className }) => (
  <div className={`${styles.card} ${className}`}>{children}</div>
);

const Section = ({ title, children }) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.8 }}
    className={styles.section}
  >
    <h2 className={styles.sectionTitle}>{title}</h2>
    <div className={styles.sectionContent}>{children}</div>
  </motion.section>
);

const MediaRow = ({ articles }) => {
  const scrollRef = useRef(null);
  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(true);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftFade(scrollLeft > 0);
      setShowRightFade(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      handleScroll(); // Check initial state
      return () => scrollElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <div className={styles.mediaRowContainer}>
      {showLeftFade && <div className={styles.gradientLeft}></div>}
      {showRightFade && <div className={styles.gradientRight}></div>}
      <div className={styles.mediaRow} ref={scrollRef}>
        {articles.map((article, index) => (
          <motion.div
            key={index}
            className={styles.articleCard}
            whileHover={{ scale: 1.05 }}
          >
            <Card>
              <div className={styles.articleContent}>
                {article.image ? (
                  <img
                    src={article.image}
                    alt={article.title}
                    className={styles.articleImage}
                  />
                ) : (
                  <h3 className={styles.articleSource}>{article.source}</h3>
                )}

                <p className={styles.articleTitle}>{article.title}</p>
              </div>
              <div className={styles.articleMeta}>
                <span>{article.date}</span>
              </div>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const AwardsRow = ({ items }) => {
  const scrollRef = useRef(null);
  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(true);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftFade(scrollLeft > 0);
      setShowRightFade(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      handleScroll(); // Check initial state
      return () => scrollElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <div className={styles.mediaRowContainer}>
      {showLeftFade && <div className={styles.gradientLeft}></div>}
      {showRightFade && <div className={styles.gradientRight}></div>}
      <div className={styles.mediaRow} ref={scrollRef}>
        {items.map((item, index) => (
          <motion.div
            key={index}
            className={styles.mediaCard}
            whileHover={{ scale: 1.05 }}
          >
            <Card className={styles.awardCard}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={item.image}
                  alt={item.title}
                  className={styles.mediaImage}
                />
                <div className={styles.mediaContent}>
                  <h3 className={styles.mediaTitle}>{item.title}</h3>
                  {item.description}
                </div>
              </a>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const NewsRoom = () => {
  const mediaArticles = [
    {
      image: media1,
      source: "The Economic Times",
      title:
        "NowPurchase is a Kolkata-based technology-driven procurement solution for metal manufacturers that suggests and delivers production inputs with the lowest cost of manufacturing",
      readTime: "",
    },
    {
      image: media2,
      source: "Business Standard",
      title:
        "Kolkata-based NowPurchase plans to use the funding to expand its procurement business by establishing a global supplier base, expanding into other parts of India, and trengthening its technology team",
      // date: "15th May, 2024",
      readTime: "",
    },
    {
      image: media3,
      source: "Mint",
      title:
        "B2B marketplace NowPurchase picks up $2.4 million in funding led by Orios, others. NowPurchase said it will use the funds towards expanding its procurement business by establishing …",
      readTime: "",
    },
    {
      image: media4,
      title:
        "Kolkata Based B2B Marketplace NowPurchase Raises 2.4M Funds to be used to grow business across newer geographies and invest in its proprietary software platform, startups, NowPurchase, funding",
      readTime: "",
    },
  ];

  const awards = [
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            NowPurchase is proud to be a member of the{" "}
            <strong>Recycled Materials Association</strong>, supporting
            sustainability, resilience, and essential recycling practices.
          </p>
        </div>
      ),
      image: media9,
      link: "https://www.isri.org/",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Our paper on MetalCloud was honoured as a{" "}
            <strong>Top 10 Selection</strong> at the WFO Young Researchers
            Conference 2024
          </p>
        </div>
      ),
      image: media10,
      link: "https://www.thewfo.com/",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Top
            <strong> 500 High-Growth Companies </strong>in the Asia-Pacific
            Region
          </p>
        </div>
      ),
      image: FTmedia,
      link: "https://www.ft.com/content/dbac4faa-5a8c-4ba9-8425-12ae727e0d05",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Featured in <strong>Financial Times Fastest Growing List</strong>
          </p>
        </div>
      ),
      image: media5,
      link: "https://www.ft.com/high-growth-asia-pacific-ranking-2023",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Received <br />
            <strong>Dun & Bradstreet Startup50 2023</strong> Award
          </p>
        </div>
      ),
      image: media6,
      link: "https://www.dnb.co.in/file/publications/the-next-big-leap/54/",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Featured in{" "}
            <strong>
              {" "}
              The Economic Times Top 50 India’s Growth Champions 2023
            </strong>
          </p>
        </div>
      ),
      image: media8,
      link: "https://economictimes.indiatimes.com/indias-growth-champions-2023-ranks-nations-fastest-growing-companies/articleshow/98454662.cms?",
    },
  ];

  return (
    <AnimatePresence>
      <Container>
        <div className={styles.titleContainer}>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.8 }}
            className={styles.title}
          >
            <span style={{ color: "#1579be" }} className={styles.title}>
              NowPurchase
            </span>{" "}
            Newsroom
          </motion.h1>
        </div>

        <Section title="Press Coverage">
          <MediaRow articles={mediaArticles} />
        </Section>

        <Section title="Our Accolades & Associations">
          <AwardsRow items={awards} />
        </Section>
      </Container>
      <MediaInvestor />
    </AnimatePresence>
  );
};

export default NewsRoom;
